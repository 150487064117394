<template>
    <div class="login-page bg-6 of-h">

        <div class="row p-0 m-0" style="min-height: 100vh;">
            <div class="col-12 col-lg-6 sec">
                <div @click="$router.push('/')" class="pl-lg-5 ml-lg-5 mt-5 cursor-pointer home-icon">
                </div>
                <div class="fl-y fl-j-c h-75">
                    <div class="sec-1 ">
                        <p class="grey"></p>

                        <div class="lg-no-dis set-im mb-5 mb-lg-0">
                            <img class="" src="../../assets/web/login/logo_login.png">
                        </div>
                        <p class="font-jost-semi-bold fs-6 mt-5  mb-1">KLDB Password Reset</p>
                    </div>
                    <b-form :key="formKey" ref="resetForm" :save-url="addUrl" @success="formSuccess"
                            @failure="formError" v-slot="{model, loading}"
                            :save-params="{username: $route.params.username}">
                        <div class="section2 mt-4 row">
                            <div class="col-12">
                                <div class="fl-x fl-j-c">
                                    <validated-input size="sm" label="Enter new password" placeholder=""
                                                     type="password" name="New Password"
                                                     v-model="model.new_password"
                                                     class="py-1 form-input-w-18re c-input-select-log-white c-login-input font-proxima-semi-bold"
                                                     :disabled="loading" :rules="{required : true}"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="fl-x fl-j-c">
                                    <validated-input size="sm" label="Confirm new password" placeholder=""
                                                     type="password" name="Confirm Password"
                                                     v-model="model.confirm_password"
                                                     class="py-1 form-input-w-18re c-input-select-log-white c-login-input font-proxima-semi-bold"
                                                     :disabled="loading" :rules="{required : true}"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="fl-x fl-j-c">
                                    <div class="w-22re c-input-select-log-white font-proxima-semi-bold text-danger"
                                         v-if="errorMessage" v-html="errorMessage"></div>
                                </div>
                            </div>
                            <div class="section-3 col-12 mt-3">
                                <btn border-radius="1" text="Submit" loading-text="Validating..."
                                     class="pbtn-1 font-proxima-semi-bold"
                                     :disabled="loading" :loading="loading"></btn>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>

            <div class="col-12 col-lg-6 section-4 d-sm-none">
                <div class="custom-border"></div>
                <div class="fl-x fl-a-c h-100">
                    <div class="pos-r p-3">
                        <div class="p-all">
                            <div class="section-5 mt-4">
                                <img class="text-center" src="../../assets/web/login/logoo.png">
                            </div>
                            <div class="section-6 mt-6 mb-3">
                                <h2 class="font-jost-medium fs-6 mb-4">Welcome To KLDB Training Courses</h2>
                                <p class="font-lato-medium fs--1  lh" align="justify">What our variations of
                                    passages with lives psumedavailable alteration in some variations of passages orem
                                    Ipsume passages with lives psum available alterat in some variations of passages
                                    orem Ipsume lives psum available alteration in some variations passages orem Ipsume
                                    variety passages orem Ipsume lives psum available alteration n some variations
                                    passages orem Ips variety </p>
                            </div>
                            <div class="mb-6 mt-4 custom-center">
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import urls from '@/data/urls';

export default {
    name: 'SetNewPassword',

    data () {
        return {
            formKey: 1,
            errorMessage: '',
            addUrl: urls.auth.resetPassword
        };
    },

    methods: {
        formSuccess () {
            this.$notify('Your new password has been updated. Please Login to continue', 'Success', {
                type: 'success'
            });
            this.$router.push('/login/');
            this.formKey += 1;
        },

        formError (response) {
            const data = response.data;
            if (data.errors.Username) {
                this.$notify(data.errors.Username, 'Failed', {
                    type: 'danger'
                });
            } else {
                this.$notify('There were some errors while communicating with the server. Please refresh the page or try again later.', 'Failed', {
                    type: 'danger'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.form-input-w-22re .form-control {
    width: 22rem !important;
    margin-top: 8px !important;
}

.sec {
    background-color: #f3f3f3;
}

.lg-no-dis {
    display: none;
}

.pbtn-1 {
    padding-left: 3.5rem !important;
    padding-bottom: 0.7rem !important;
    padding-top: 0.7rem !important;
    padding-right: 3.5rem !important;
}

.lh {
    line-height: 1.5rem !important;
}

.sec-1 {
    text-align: center;

}

.strip-color {
    background-color: #115a3d;
    width: 100%;
    height: 0.8rem;
    position: absolute;
    left: 0px;
    /*top: 565px;*/
    bottom: 0px;
}

.j-center {
    justify-content: center;
}

.sec-left {
    margin-left: 136px;
}

.sec-left1 {
    margin-left: 340px;
    position: relative;
    top: -11px;
}

.section-3 {
    text-align: center;
}

.section-4 {
    background-image: url("../../assets/web/login/login.jpg");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

}

.social-bg {
    background-image: url('../../assets/web/login/bgi-new.png');
    background-size: cover;
    background-position: 0% 70%;
    @media (min-width: 768px) and (max-width: 1024px) {
        background-position: 26% 70% !important;
    }
}

.section-5 img {
    width: 226px;
    height: 120px;
    @media (min-width: 1400px) {
        width: 255px;
        height: 139px;
    }
}

.section-5 {
    display: flex;
    color: white;
    justify-content: center;
}

.section-6 {
    text-align: center;
    color: white;

}

.try img {
    height: 100px;
    width: 600px;
}

.custom-border {
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    position: absolute;
    top: 2%;
    right: 2%;
    left: 2%;
    bottom: 2%;

}

.p-all {
    padding: 3rem;
}

hr {
    border-top: 1.5px solid white;
    width: 13%;
}

.custom-center {

    display: flex;
    justify-content: center;
}

h6 {

    margin: 20px auto;
    margin-bottom: 0;
    color: #36454F;
    text-align: center;
    font-size: 13px;
    /*max-width: 360px;*/
    max-width: 275px;
    position: relative;
}

h6:before {
    content: "";
    display: block;
    /*width: 130px;*/
    width: 97px;
    height: 0.5px;
    background: darkgrey;
    left: 0;
    top: 50%;
    position: absolute;
}

.container {
    min-height: 100vh;

}

h6:after {
    content: "";
    display: block;
    /*width: 130px;*/
    width: 97px;
    height: 0.5px;
    background: darkgrey;
    right: 0;
    top: 50%;
    bottom: 0;
    position: absolute;
}

.ml-20rem {
    margin-left: 20rem;
}

.fb {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.strip img {
    width: 100%;
    height: 8px;
    position: absolute;
    left: 0px;
    /*top: 565px;*/
    bottom: 0px;

}

.nobs {
    display: none;
}

.text-black {
    color: #202020 !important;
}

.h-88 {
    height: 88%;
}

.h-75 {
    height: 75%;
}

@media (max-width: 750px) {
    .try img {
        height: 200px;
        width: 400px;
        width: 400px;
    }
    .pbtn-1 {
        padding-left: 2.5rem !important;
        padding-bottom: 0.7rem !important;
        padding-top: 0.7rem !important;
        padding-right: 2.5rem !important;
    }

    .nobs {
        display: contents;
    }

    .nobs-no {
        display: none;
    }

    .custom-border {
        border: 0.5px solid #ffffff;
        border-radius: 7px;
        position: relative;
        top: 2%;
        right: 2%;
        left: 2%;
        bottom: 2%;

    }

    .sec-left1 {
        margin-left: 195px;
        position: relative;
        top: -11px;
    }

    .sm-res {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .d-sm-none {
        display: none;
    }

    .lg-no-dis {
        display: contents;
    }

    .set-im img {
        //width: 206px;
        //height: 108px;
        height: 6.5rem;
        margin-top: 1rem;
        //margin-left: -10rem;

    }

    .sec-left {
        margin-left: 49px;
    }

    .sec-left1 {
        margin-left: 195px;
    }

    //.login-page {
    //    background-color: whitesmoke;
    //}

    .strip img {
        /*width: 642px;*/
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0px;
        top: 848px;
        bottom: 0px;
    }
}

.home-icon {
    position: relative;
    width: 1.75rem !important;
    height: 1.75rem !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../assets/web/login/home-icon.png");
}

</style>
